<block-ui>
    <div class="section">
        @defer (when loadDeferredView()) {
        <left-content [selector]="'privacy'"></left-content>
        <div class="content">
            <div class="container">
                @if(isJoint()) {
                <div class="tabContainer">
                    <mat-tab-group>
                        <mat-tab [label]="journeyClientService.getClientName('primary')">
                            <div class="accordionJointClient">
                                <consent-questions [(questions)]="questionsPrimary" />
                            </div>
                        </mat-tab>
                        <mat-tab [label]="journeyClientService.getClientName('spouse')">
                            <div class="accordionJointClient">
                                <consent-questions [(questions)]="questionsSpouse" />
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
                }
                @else {
                <div class="accordionSingleClient">
                    <consent-questions [(questions)]="questionsPrimary" />
                </div>
                }
                <div class="actions">
                    <page-navigation [pageID]="pagesEnum.KeepYourInformationSafe" />
                </div>
            </div>
        </div>
        } @placeholder {
        <mat-spinner></mat-spinner>
        }
    </div>
</block-ui>