import { ChangeDetectionStrategy, Component, EventEmitter, Injector, OnInit, Output, signal } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { PrivacyQuestionService, Question } from '../../../services/privacy-question.service';
import { BlockUIModule } from 'ng-block-ui';
import { ComponentBase } from '../../../component-base';
import { ConsentQuestionsComponent } from './consent/consent-questions.component';
import { HouseholdService } from '../../../services/household.service';
import { JourneyClientService } from '../../../services/journey-client.service';
import { LeftContentComponent } from '../../../shared/left-content/left-content.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatTabsModule } from '@angular/material/tabs';
import { MwToggleComponent } from '../../../controls/mw-toggle/mw-toggle.component';
import { PageNavigationComponent } from '../../../shared/page-navigation/page-navigation.component';
import { Pages } from '../../../enums/page';
import { PrivacyQuestion } from '../../../entities/privacy-question';
import { TextTemplateField } from '../../../enums/text-template-field';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'privacy',
  standalone: true,
  imports: [MatIconModule, MatProgressSpinner, LeftContentComponent, MatSlideToggleModule, MwToggleComponent, PageNavigationComponent, MatSnackBarModule, BlockUIModule,
    MatTabsModule, ConsentQuestionsComponent],
  templateUrl: './privacy.component.html',
  styleUrl: './privacy.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyComponent extends ComponentBase implements OnInit {
  constructor(
    public privacyQuestionService: PrivacyQuestionService,
    private snackBar: MatSnackBar,
    private householdService: HouseholdService,
    private injector: Injector,
    public journeyClientService: JourneyClientService
  ) {
    super(Pages.KeepYourInformationSafe, injector, 'privacy');
  }

  @Output() readonly showSaveAndExitButton = new EventEmitter<boolean>();
  loadDeferredView = signal(false);

  pagesEnum = Pages;
  questionsPrimary = signal<AnswerableQuestion[]>([]);
  questionsSpouse = signal<AnswerableQuestion[]>([]);

  isJoint = signal(false);
  ngOnInit() {
    if (this.journeyClientService.primaryJourneyClient() && this.journeyClientService.spouseJourneyClient()) {
      this.isJoint.set(true);
    }

    if (this.householdService.household) this.showSaveAndExitButton.emit(true);

    this.privacyQuestionService.getPrivacyQuestions(this.journeyService.getNonNullableJourney().journeyID)
      .subscribe(response => {

        this.questionsPrimary.set(this.initializeQuestionsForClient(this.journeyClientService.getNonNullablePrimaryClientID(), response));

        if (this.journeyClientService.spouseJourneyClient()) {
          this.questionsSpouse.set(this.initializeQuestionsForClient(this.journeyClientService.getNonNullableSpouseClientID(), response));
        }

        this.loadDeferredView.set(true);
      });
  }

  private initializeQuestionsForClient(clientId: string, privacyQuestions: PrivacyQuestion[]): AnswerableQuestion[] {
    return this.privacyQuestionService.referenceQuestions.map(x => ({
      iD: x.iD,
      isRequired: x.isRequired,
      selector: x.selector,
      clientId,
      questionText: this.getTextTemplateElementText(TextTemplateField.Text, x.selector),
      questionSubText: this.getTextTemplateElementText(TextTemplateField.SubText, x.selector),
      answer: privacyQuestions.find(y => y.journeyClientID === clientId && y.questionID === x.iD)?.answer ?? false
    }));
  }

  override async save(): Promise<boolean> {
    try {
      this.snackBar.dismiss();
      this.blockUI.start();
      if (!this.validateQuestions()) {
        return false;
      }
      await this.saveQuestions();
      return await super.save();
    } finally {
      this.blockUI.stop();
    }
  }

  private validateQuestions(): boolean {
    for (const question of [...this.questionsPrimary(), ...this.questionsSpouse()]) {
      if (!question.answer && question.isRequired) {
        this.snackBar.open("Please complete all required fields", "Close");
        return false;
      }
    }
    return true;
  }

  private async saveQuestions(): Promise<void> {
    if (!this.journeyService.journey?.journeyID || !this.householdService.household) return;

    await firstValueFrom(this.privacyQuestionService.savePrivacyQuestions(this.createPrivacyQuestions([...this.questionsPrimary(), ...this.questionsSpouse()])));
  }

  private createPrivacyQuestions(answeredQuestions: AnswerableQuestion[]): PrivacyQuestion[] {
    return answeredQuestions.map(x => this.privacyQuestionService.createPrivacyQuestion(this.journeyService.getNonNullableJourney().journeyID, x.iD, x.answer, x.clientId))
  }
}

export interface AnswerableQuestion extends Question {
  answer: boolean;
  clientId: string;
  questionText: string;
  questionSubText: string;
}